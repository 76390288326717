<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import {
	addBlackUser,
	addWhiteUser,
	editUser,
	getAllUsers,
	getBlackUsers,
	getWhiteUsers,
	removeBlackUser,
	removeWhiteUser,
} from '@/utils/users';
import { onBeforeMount, onMounted, ref } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { load } from '@/utils/profile';

const notSortedUsers = ref([]);
const users = ref([]);
const filterInput = ref('');
const filterSelection = ref('Not');

const whiteUsers = ref([]);
const blackUsers = ref([]);

const reload = async () => {
	notSortedUsers.value = await getAllUsers();
	users.value = await getAllUsers();
	whiteUsers.value = await getWhiteUsers();
	blackUsers.value = await getBlackUsers();
};

const sortedList = () => {
	if (
		filterSelection.value !== 'Not' &&
		filterSelection.value !== 'InWhitelist' &&
		filterSelection.value !== 'InBlacklist'
	) {
		return users.value.sort((a, b) => {
			switch (filterSelection.value) {
				case 'BalanceFromMax':
					return a.balance.ton - b.balance.ton;
				case 'BalanceFromMin':
					return b.balance.ton - a.balance.ton;
				case 'IncomeFromMax':
					return a.earnings.income.ton - b.earnings.income.ton;
				case 'IncomeFromMin':
					return b.earnings.income.ton - a.earnings.income.ton;
				case 'Not':
				default:
					return 0;
			}
		});
	} else {
		if (filterSelection.value === 'Not') {
			return notSortedUsers.value;
		} else if (filterSelection.value === 'InWhitelist') {
			return notSortedUsers.value.filter((user) => isConstainsInWhitelist(user._id));
		} else if (filterSelection.value === 'InBlacklist') {
			return notSortedUsers.value.filter((user) => isConstainsInBlacklist(user._id));
		} else {
			return notSortedUsers.value;
		}
	}
};

const filteredList = () => {
	let result = sortedList().filter((user) =>
		String(user._id).includes(filterInput.value.toLowerCase())
	);

	if (!result || result.length === 0) {
		result = sortedList().filter((user) =>
			user.username.toLowerCase().includes(filterInput.value.toLowerCase())
		);
	}

	return result;
};

const usersList = () => {
	if (!filteredList().length || filteredList().length == 0) {
		return sortedList();
	} else {
		return filteredList();
	}
};

const isConstainsInWhitelist = (id) => whiteUsers.value.find((whiteUser) => whiteUser._id === id);
const isConstainsInBlacklist = (id) => blackUsers.value.find((blackUsers) => blackUsers._id === id);

const addToWhitelist = async (id) => {
	await addWhiteUser(id);
	await reload();
};
const addToBalcklist = async (id) => {
	await addBlackUser(id);
	await reload();
};

const removeFromWhitelist = async (id) => {
	await removeWhiteUser(id);
	await reload();
};
const removeFromBlacklist = async (id) => {
	await removeBlackUser(id);
	await reload();
};

const updateUser = async (id, data) => {
	await editUser(id, JSON.stringify(data));
};

const isShowHistoryWindow = ref(false);
const currentUser = ref(null);

const openHistory = (user) => {
	isShowHistoryWindow.value = true;
	currentUser.value = JSON.parse(JSON.stringify(user));

	if (currentUser.value.transactions_histories?.length > 0) {
		currentUser.value.transactions_histories.reverse();
	}

	let history = document.getElementById('window-history');

	if (history) {
		history.className = 'window-history';
	}
};

const closeHistory = () => {
	isShowHistoryWindow.value = false;
	currentUser.value = null;

	let history = document.getElementById('window-history');

	if (history) {
		history.className = 'window-history disable-block';
	}
};

const translateAction = (type) => {
	let color = 'white';
	let text = 'Неизвестное действие';

	switch (type) {
		case 'Unknown': {
			color = 'white';
			text = 'Неизвестное действие';
			break;
		}
		case 'Deposit': {
			color = 'green';
			text = 'Пополнение счета';
			break;
		}
		case 'Barrel': {
			color = 'yellow';
			text = 'Тапалка';
			break;
		}
		case 'CompleteTask': {
			color = 'yellow';
			text = 'Выполнено задание';
			break;
		}
		case 'Repost': {
			color = 'yellow';
			text = 'Репост';
			break;
		}
		case 'Withdraw': {
			color = 'red';
			text = 'Вывод со счета';
			break;
		}
		case 'BuyBee': {
			color = 'green';
			text = 'Покупка пчелы';
			break;
		}
		case 'BuyBooster': {
			color = 'green';
			text = 'Покупка бустера';
			break;
		}
		case 'DepositReferall': {
			color = 'yellow';
			text = 'Пополнение счета рефералом';
			break;
		}
		case 'CollectIncome': {
			color = 'red';
			text = 'Сбор урожая';
			break;
		}
		case 'Staking': {
			color = 'yellow';
			text = 'Стейкинг';
			break;
		}
		default: {
			color = 'white';
			text = 'Неизвестное действие';
			break;
		}
	}

	return {
		color: color,
		text: text,
	};
};

onMounted(async () => {
	await reload();
});

onBeforeMount(async () => {
	await load();
});
</script>

<template>
	<main class="page-menu">
		<template v-if="currentIsConnectedStatus">
			<button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
			<div class="main-container">
				<div class="block_payments">
					<div class="container">
						<div class="group">
							<div class="filters">
								<input
									class="filter-element"
									type="text"
									v-model="filterInput"
									placeholder="Введите ID или username"
								/>
								<select class="filter-element" v-model="filterSelection">
									<option value="Not">Не сортировать</option>
									<option value="BalanceFromMax">По балансу: по возрастанию</option>
									<option value="BalanceFromMin">По балансу: по убыванию</option>
									<option value="IncomeFromMax">По выводу: по возрастанию</option>
									<option value="IncomeFromMin">По выводу: по убыванию</option>
									<option value="InWhitelist">Белый список</option>
									<option value="InBlacklist">Черный список</option>
								</select>
							</div>
							<p>
								Пользователей:
								<h style="color: red; font-weight: bold">{{ usersList()?.length ?? 0 }}</h>
							</p>
							<table class="block-table">
								<thead>
									<tr>
										<th>ID</th>
										<th>Username</th>
										<th>Пригласитель</th>
										<th>Баланс</th>
										<th>Нужно прокрутить</th>
										<th>Пчелы</th>
										<th>Авто-сбор</th>
										<th>Рефералы</th>
										<th>Собранно</th>
										<th>Действие</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="user in usersList()" :key="user">
										<td data-label="ID">{{ user._id }}</td>
										<td data-label="Username">{{ user.username }}</td>
										<td data-label="Пригласитель">{{ user.inviter }}</td>
										<td data-label="Баланс">
											<p><input type="number" v-model="user.balance.ton" /> TON</p>
											<p><input type="number" v-model="user.balance.honey" /> GH</p>
										</td>
										<td data-label="Нужно прокрутить">
											<template v-if="user.balance.withheld">
												<p><input type="number" v-model="user.balance.withheld.ton" /> TON</p>
											</template>
											<template v-else>
												<p>0 TON</p>
											</template>
										</td>
										<td data-label="Пчелы">
											<template v-if="user.bees.length > 0">
												<p v-for="bee in user.bees" :key="bee">
													{{ bee.type }}: <input type="number" v-model="bee.count" />
												</p>
											</template>
											<template v-else>
												<p>Нет</p>
											</template>
										</td>
										<td data-label="Авто-сбор">
											<!-- <p>{{ user.boosters[0].type }}:</p> -->
											<div class="radio-button">
												<input
													type="radio"
													id="Block"
													:value="true"
													v-model="user.boosters[0].is_active"
												/>
												<label for="Block">Есть</label>
											</div>
											<div class="radio-button">
												<input
													type="radio"
													id="NotBlock"
													:value="false"
													v-model="user.boosters[0].is_active"
												/>
												<label for="NotBlock">Нет</label>
											</div>
										</td>
										<td data-label="Рефералы">
											<template v-if="user.referrals.length > 0">
												<p v-for="referral in user.referrals" :key="referral">
													{{ referral.idUser }}
												</p>
											</template>
											<template v-else>
												<p>Нет</p>
											</template>
										</td>
										<td data-label="Собранно">
											<p>{{ user.earnings.income.ton }} TON</p>
											<p>{{ user.earnings.income.honey }} GH</p>
										</td>
										<td>
											<div class="user-actions">
												<button @click="openHistory(user)">История транзакций</button>
												<button @click="updateUser(user._id, user)">Обновить данные</button>
												<button @click="reload()">Отменить изменения</button>
												<template v-if="!isConstainsInWhitelist(user._id)">
													<template v-if="isConstainsInBlacklist(user._id)">
														<button @click="removeFromBlacklist(user._id)">
															Убрать из черного списка
														</button>
													</template>
													<template v-else>
														<button @click="addToBalcklist(user._id)">
															Добавить в черный список
														</button>
													</template>
												</template>
												<template v-if="!isConstainsInBlacklist(user._id)">
													<template v-if="isConstainsInWhitelist(user._id)">
														<button @click="removeFromWhitelist(user._id)">
															Убрать из белого списока
														</button>
													</template>
													<template v-else>
														<button @click="addToWhitelist(user._id)">
															Добавить в белый список
														</button>
													</template>
												</template>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<template v-if="isShowHistoryWindow">
				<div id="window-history" class="window-history">
					<div class="history-header">
						<h1>
							История транзакций пользователя -
							{{ currentUser?.username ?? 'Неизвестный пользователь' }} ({{
								currentUser?._id ?? 'Неизвестно'
							}})
						</h1>
						<button class="history-button" @click="closeHistory">Закрыть</button>
					</div>
					<div class="history-main">
						<template v-if="currentUser?.transactions_histories">
							<table class="block-table">
								<thead>
									<tr>
										<th>Дата</th>
										<th>Действие</th>
										<th>Сумма</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="transaction in currentUser.transactions_histories"
										:key="transaction"
										:style="`background-color: ${translateAction(transaction?.action).color};`"
									>
										<td data-label="Дата">{{ transaction?.data ?? 'Нет даты' }}</td>
										<td data-label="Действие">
											{{
												transaction?.action
													? translateAction(transaction?.action).text
													: 'Неизвестное действие'
											}}
										</td>
										<td data-label="Сумма">
											{{ transaction?.amount?.ton ?? 0 }} TON
											{{ transaction?.amount?.honey ?? 0 }} GH
										</td>
									</tr>
								</tbody>
							</table>
						</template>
						<template v-else>
							<p>Пользователь не имеет истории транзакций</p>
						</template>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="wallet-button">
				<WalletModal />
			</div>
		</template>
	</main>
</template>

<style>
.window-history {
	position: fixed;
	top: 0;
	left: 0;
	background-color: white;
	width: -webkit-fill-available;
	height: -webkit-fill-available;
	padding: 3vw;
	z-index: 999;
	overflow: scroll;
}

.history-header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
}

.history-button {
	padding-left: 5vw;
	padding-right: 5vw;
	border-radius: 45px;
	margin: 1vw;
	background-color: red;
	color: white;
}

.history-main {
	display: flex;
}

.user-actions {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: stretch;
	gap: 1vw;
	padding: 1vw;
}
</style>
