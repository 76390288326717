import { ref } from 'vue';
import { mnemonicToWalletKey } from '@ton/crypto';
import { TonClient } from '@ton/ton';
import { Address, SendMode, internal, toNano, comment } from '@ton/core';
import { HighloadWalletV3 } from '@tonkite/highload-wallet-v3';
import { load } from './profile';
import { tonConnect } from './tonConnect';
import { getAllPayments, payments, count } from './payments';
import { getRequest, putRequest } from './restapi';
import { API_ADDRESS_SERVER } from '@/config';
import { getHttpEndpoint, getHttpEndpoints } from '@orbs-network/ton-access';

const key = ref(null);
const hightwallet = ref(null);

const mnemonic = ref(null);
export const address = ref(null);
const workchain = ref(null);

const client = ref(null);
export const balance = ref(0);
export const queryIdSequence = ref(null);
export const idSequence = ref(0);

export const isSendingProccess = ref(false);

export const connect = async (mnem) => {
	mnemonic.value = mnem;
	key.value = await mnemonicToWalletKey(mnemonic.value.split(' '));

	// initialize ton rpc client on mainnet
	const endpoints = await getHttpEndpoints();
	const endpoint = endpoints[2];
	await getHttpEndpoint();
	client.value = new TonClient({ endpoint });

	queryIdSequence.value = HighloadWalletV3.newSequence();
	hightwallet.value = client.value.open(
		new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
	);
	idSequence.value = queryIdSequence.value.current();

	address.value = Address.parse(hightwallet.value.address.toString()).toString({
		urlSafe: true,
		bounceable: false,
		testOnly: false,
	});
	workchain.value = hightwallet.value.address.workChain;
};

export const getData = async () => {
	if (queryIdSequence.value === null) {
		queryIdSequence.value = HighloadWalletV3.newSequence();
		hightwallet.value = client.value.open(
			new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
		);
	}

	idSequence.value = queryIdSequence.value.current();
	queryIdSequence.value = HighloadWalletV3.restoreSequence(idSequence.value);
	hightwallet.value = client.value.open(
		new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
	);

	// query balance from chain
	balance.value = await client.value.getBalance(hightwallet.value.address);

	// make sure wallet is deployed
	if (!(await client.value.isContractDeployed(hightwallet.value.address))) {
		return;
	}
};

export const withdrawTransaction = async (address, amount) => {
	await getData();
	isSendingProccess.value = true;

	try {
		await hightwallet.value.sendBatch(key.value.secretKey, {
			messages: [
				{
					mode: SendMode.CARRY_ALL_REMAINING_BALANCE,
					message: internal({
						to: Address.parse(address).toString({
							urlSafe: true,
							bounceable: false,
							testOnly: false,
						}),
						value: toNano(amount),
						body: comment(`Withdraw TON: ${amount}`),
						bounce: false,
					}),
				},
			],

			/*
			 * NOTE: This it subtotal for all messages + fees.
			 *       This value can be omitted, but it's recommended to specify it.
			 *       Otherwise, batches will be sent in different blocks (e.a. time-consuming).
			 */
			//valuePerBatch: toNano(balance.value),
			createdAt: Math.floor(Date.now() / 1000) - 60,
		});

		await init();
		await getData();
	} catch (e) {
		//alert('Ошибка при отправки транзакции!');
		console.error(e);
		//queryIdSequence.value = HighloadWalletV3.emergencySequence();
		idSequence.value = queryIdSequence.value.next();
		queryIdSequence.value = HighloadWalletV3.restoreSequence(idSequence.value);
		hightwallet.value = client.value.open(
			new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
		);
		await withdrawTransaction(address, amount);
	}

	isSendingProccess.value = false;
};

export const sendTransaction = async (transfers) => {
	const messages = [];
	for (const message of transfers) {
		messages.push({ address: message.address, amount: toNano(message.amount).toString() });
	}
	let transaction = {
		validUntil: Math.floor(Date.now() / 1000) + 60,
		messages: messages,
	};
	try {
		const result = (await tonConnect()).sendTransaction(transaction);
		if (await result) {
			return true;
		}
	} catch (e) {
		return false;
	}
};

// export const sendTransaction = async (address, amount, paymentID, transactionID) => {
// 	await getData();
// 	isSendingProccess.value = true;

// 	try {
// 		await hightwallet.value.sendBatch(key.value.secretKey, {
// 			messages: [
// 				{
// 					mode: SendMode.PAY_GAS_SEPARATELY,
// 					message: internal({
// 						to: Address.parse(address).toString({
// 							urlSafe: true,
// 							bounceable: false,
// 							testOnly: false,
// 						}),
// 						value: toNano(amount),
// 						body: comment(`Income ID: ${transactionID} TON: ${amount}`),
// 						bounce: false,
// 					}),
// 				},
// 				/* ... */
// 			],

// 			/*
// 			 * NOTE: This it subtotal for all messages + fees.
// 			 *       This value can be omitted, but it's recommended to specify it.
// 			 *       Otherwise, batches will be sent in different blocks (e.a. time-consuming).
// 			 */
// 			//valuePerBatch: toNano(balance.value),
// 			createdAt: Math.floor(Date.now() / 1000) - 60,
// 		});

// 		console.log('transaction confirmed!');

// 		await getRequest(`${API_ADDRESS_SERVER}/income/send/payment/${paymentID}/${transactionID}`);
// 		await init();
// 		await getData();
// 	} catch (e) {
// 		//alert('Ошибка при отправки транзакции!');
// 		console.error(e);
// 		//queryIdSequence.value = HighloadWalletV3.emergencySequence();
// 		idSequence.value = queryIdSequence.value.next();
// 		queryIdSequence.value = HighloadWalletV3.restoreSequence(idSequence.value);
// 		hightwallet.value = client.value.open(
// 			new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
// 		);
// 		await sendTransaction(address, amount, paymentID, transactionID);
// 	}

// 	isSendingProccess.value = false;
// };

export const sendTransactions = async (transactions) => {
	await getData();
	isSendingProccess.value = true;

	try {
		let messagesData = [];

		transactions.forEach((transaction) => {
			if (
				(Address.isAddress(transaction.address) || Address.isRaw(transaction.address)) &&
				!transaction.isCompleted
			) {
				messagesData.push({
					mode: SendMode.PAY_GAS_SEPARATELY,
					message: internal({
						to: Address.parse(transaction.address).toString({
							urlSafe: true,
							bounceable: false,
							testOnly: false,
						}),
						value: toNano(transaction.ton),
						body: comment(
							`Income ID: ${transaction.id} TON: ${transaction.ton} DATE: ${transaction.date}`
						),
						bounce: false,
					}),
				});
			}
		});

		await hightwallet.value.sendBatch(key.value.secretKey, {
			messages: messagesData,

			/*
			 * NOTE: This it subtotal for all messages + fees.
			 *       This value can be omitted, but it's recommended to specify it.
			 *       Otherwise, batches will be sent in different blocks (e.a. time-consuming).
			 */
			//valuePerBatch: toNano(balance.value),
			createdAt: Math.floor(Date.now() / 1000) - 60,
		});

		transactions.forEach(async (transaction) => {
			await getRequest(
				`${API_ADDRESS_SERVER}/income/send/payment/${transaction.paymentId}/${transaction.id}`
			);
		});
	} catch (e) {
		//alert('Ошибка при отправки транзакций!');
		console.error(e);
		//queryIdSequence.value = HighloadWalletV3.emergencySequence();
		idSequence.value = queryIdSequence.value.next();
		queryIdSequence.value = HighloadWalletV3.restoreSequence(idSequence.value);
		hightwallet.value = client.value.open(
			new HighloadWalletV3(queryIdSequence.value, key.value.publicKey)
		);
		await sendTransactions(transactions);
	}

	isSendingProccess.value = false;

	await sleep(3000);
	await init();
	await getData();
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const countAll = () => {
	let countTon = 0;

	payments.value.forEach((payment) => {
		payment.transactions.forEach((transaction) => {
			if (!transaction.isCompleted) {
				countTon += transaction.income.ton;
			}
		});
	});

	count.value = countTon;
};

const init = async () => {
	await load();
	payments.value = await getAllPayments();
	countAll();
};

export const getWalletAddressInGame = async () => {
	let data = await getRequest(`${API_ADDRESS_SERVER}/wallet/address/get`);
	return data?.address ?? '';
};

export const updateWalletAddressInGame = async () => {
	let data = await putRequest(`${API_ADDRESS_SERVER}/wallet/address/update/${address.value}`);

	if (data !== null && data.address !== null && data.address !== '') {
		alert(`Адресс биржи в игре успешно изменен на ${address.value}`);
		return data.address;
	} else {
		alert(`Ошибка смены адреса кошелька!`);
		return null;
	}
};

export const updateWalletNewAddressInGame = async (newAddress) => {
	let data = await putRequest(`${API_ADDRESS_SERVER}/wallet/address/update/${newAddress}`);

	if (data !== null && data.address !== null && data.address !== '') {
		alert(`Адресс биржи в игре успешно изменен на ${newAddress}`);
		return data.address;
	} else {
		alert(`Ошибка смены адреса кошелька!`);
		return null;
	}
};
